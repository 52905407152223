.back {
  background-color: transparent;
  border: 1px solid #ff1933;
  font-size: 20px;
  font-weight: 600;
  width: 200px;
  margin: 15px auto;
  color: #ff1933;
  display: block;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  &:hover {
    color: #fff;
    background-color: #ff1933;
    cursor: pointer;
  }
}
